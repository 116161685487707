<template>
    <div>
        <nav-bar title="集体办理申请" url="/company"></nav-bar>
        <div class="top">
            <div class="title">
                <div class="title-text">办卡类型</div>
                <div class="title-bg">
                    <img src="@/static/image/index/6.png">
                </div>
            </div>
            <van-checkbox-group v-model="checkbox">
                <van-cell-group>
                    <van-cell v-for="(item,index) in cate" :key="index" clickable
                        :title="item.cate_name + '(' + item.cate_money + '元)'" @click="toggle(index)">
                        <van-checkbox :name="item.id" :checked-color="config.main_color" ref="checkboxes"
                            slot="right-icon">
                        </van-checkbox>
                    </van-cell>
                </van-cell-group>
            </van-checkbox-group>
        </div>


        <div class="top">
            <div class="title">
                <div class="title-text">用户信息</div>
                <div class="title-bg">
                    <img src="@/static/image/index/6.png">
                </div>
            </div>
            <van-cell-group>
                <van-field label-width="70" label-class="label" v-model="data.company_name" label="单位全称"
                    placeholder="请输入单位全称"></van-field>
                <van-field label-width="70" label-class="label" v-model="data.company_username" label="联系人"
                    placeholder="请输入联系人姓名"></van-field>
                <van-field label-width="70" label-class="label" v-model="data.company_expect_people" label="预计人数"
                    placeholder="请输入预计办理人数"></van-field>
                <common-msg @callback="callback" :show="true"></common-msg>
            </van-cell-group>
        </div>

        <!--办理须知和提交按钮-->
        <div class="action">
            <div class="agree">
                <van-checkbox v-model="agree" shape="square" :checked-color="config.main_color">阅读并同意</van-checkbox>
                <span class="handle" @click="show_demand = true">《集体办理协议》</span>
            </div>
            <van-button round :disabled="!agree" type="primary" @click="submit" :color="config.main_color" size="large">
                提交申请
            </van-button>
        </div>


        <!--办理须知内容-->
        <van-action-sheet v-model="show_demand" title="用户办理须知" :round="true">
            <div class="demand" v-html="demand"></div>
        </van-action-sheet>


    </div>
</template>

<script>
    import { company_apply } from '@/api/company.js';
    import { common_set } from '@/api/common.js';
    import { card_cate } from '@/api/card.js';
    import {
        Toast,
        Notify,
        Dialog,
    } from 'vant';
    export default {
        name: 'apply',
        data() {
            return {
                checkbox: [],
                cate: [], //办卡类型
                data: {
                    company_name: '',
                    company_tel: '',
                    company_username: '',
                    company_expect_people:'',
                    code: '',
                },
                agree: false,
                show_demand: false,
                demand: '', //协议
            }
        },
        mounted() {
            this.get_cate();
            this.get_demand();
        },
        methods: {
            get_cate() {
                card_cate({}).then(res => {
                    if (res.code == 1) {
                        this.cate = res.data.cate;
                        this.radio = res.data.cate[0].id;
                        this.max_other = res.data.cate.max_other;
                    }
                });
            },

            get_demand() {
                common_set({
                    type: 'group'
                }).then(res => {
                    if (res.code == 1) {
                        this.demand = res.data.content;
                    }
                });
            },

            toggle(index) {
                this.$refs.checkboxes[index].toggle();
            },

            //发送验证码回调
            callback(e) {
                if (e.type == 'input') {
                    if (e.field == 'tel') {
                        this.data.company_tel = e.value;
                    }

                    if (e.field == 'code') {
                        this.data.code = e.value;
                    }
                } else {
                    this.data.check_code = e.info.code;
                }
            },

            //提交
            submit() {
                var that = this;
                if (that.checkbox.length == 0) {
                    Toast('请选择办卡类型');
                    return false;
                }

                if (!that.data.company_name) {
                    Toast('单位名称不得为空');
                    return false;
                }

                if (!that.data.company_username) {
                    Toast('联系人不得为空');
                    return false;
                }

                if (!that.data.company_expect_people) {
                    Toast('预计人数不得为空');
                    return false;
                }

                if (!that.data.company_tel) {
                    Toast('手机号码不得为空');
                    return false;
                }

                if (that.data.company_tel.length !== 11) {
                    Toast('办理人电话号码长度不合法');
                    return false;
                }

                if (!that.data.code) {
                    Toast('验证码不得为空');
                    return false;
                }

                var data = {
                    company_name: that.data.company_name,
                    company_username: that.data.company_username,
                    company_tel: that.data.company_tel,
                    company_expect_people: that.data.company_expect_people,
                    code: that.data.code,
                    state: 0,
                    company_authority: that.checkbox,
                    loading: true,
                    loading_text: '提交中……'
                };

                company_apply(data).then(res => {
                    if (res.code == 1) {
                        Dialog.alert({
                            message: res.msg,
                        }).then(res2 => {
                            that.url('/company');
                        });
                    }
                });
            },


            url(path) {
                if (path.indexOf("http") != -1) {
                    window.location.href = path;
                } else {
                    if (path == this.$store.getters.unionid) {
                        this.$router.back();
                    } else {
                        this.$router.push(path);
                    }
                }
            },

        }
    }
</script>

<style scoped>
    .top {
        width: calc(100% - 40px);
        margin: 20px auto;
        background: #fff;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
    }

    .title {
        background: var(--main-color);
        height: 40px;
        line-height: 40px;
        color: #fff;
        padding-left: 20px;
        position: relative;
        font-family: 'FZCS';

    }

    .title-bg {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 999;
    }

    .title-bg img {
        height: 40px;
    }

    .van-button--primary {
        background-image: url('../../static/image/index/7.png') !important;
        background-size: 100% auto !important;
        background-repeat: no-repeat;
        font-family: 'FZCS';
    }

    /deep/ .label {
        font-family: 'FZCS';
    }

    /deep/ .label,
    /deep/ .van-field__control {
        color: var(--main-color);
    }

    .action {
        margin: 20px;
        width: calc(100% - 40px);
    }

    /deep/ .label,
    /deep/ .van-field__control {
        color: var(--main-color);
    }

    .agree {
        padding-left: 0;
        color: var(--main-color);
        position: relative;
        height: 40px;
        line-height: 40px;
    }

    .agree .handle {
        position: absolute;
        left: 110px;
        top: -10px;
        font-size: 14px;
    }

    .agree .van-checkbox__label {
        color: #999;
        font-size: 14px;
    }

    .demand {
        padding: 15px;
        height: 60vh;
    }
</style>